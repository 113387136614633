
var modalClone = false;

function genModal(title){
    if(modalClone) modalClone.remove();
    modalClone = $('#Modal').clone();
    $(modalClone).find('.modal-dialog .modal-content .modal-header h4.modal-title').html(title);
    modalClone.modal();
    return modalClone;
}

function loadAnalysisTable(){
    $.ajax({
        type:'GET',
        url:'/UserAnalysis/index',
        success:function(response){
            $('#loadAnalysisSearchModal .modal-body').html(response);
        },
        error: handleAjaxError
    });
}

function loadAnalysisDataTable(md5,type,nodeid, liteMessage,monthly){
    QueryRunCounter++;
    //console.log('loadAnalysisDataTable start',QueryRunCounter);
	if(type=='national'){
        $.ajax({
            type:'GET',
            url:'/Data/ajaxAnalysis/'+md5+'/'+type+'/'+monthly
        }).done(function(response) {                
            QueryRunCounter--;
            $('#'+nodeid+' table').replaceWith(response);
            var defaultOrder = $('#'+nodeid+' table.data td.organisation').index();
            if(defaultOrder < 0) defaultOrder = 0;
            var htmlCount = Array();
            for (var i = 0; i <= defaultOrder; i++) {
                    htmlCount.push(i);
            }
            $('#'+nodeid+' table.data').DataTable({
                "paging": false,
                "searching": false,
                "ordering": true,
                "order": [[ defaultOrder, 'asc' ]],
                "info": false,
                columnDefs: [
                    { type: 'html', targets: htmlCount },
                    { type: 'suppressed', targets: '_all' }
                ]
            });
        }).fail(function(response){
            QueryRunCounter--;
            if (response.readyState < 4) {
                return true;
            }
            ajaxDisplayError($('#'+nodeid));
            handleAjaxError(response);
        });
	}else{
	    $.ajax({
            type: 'GET',
            url: '/Data/ajaxAnalysis/'+md5+'/'+type+'/'+monthly
		}).done( function(response) {
            QueryRunCounter--;
            //console.log('triggering counter minus', QueryRunCounter);
			$('#'+nodeid+' table').replaceWith(response);
			var defaultOrder = $('#'+nodeid+' table.data td.organisation').index();
			if(defaultOrder < 0) defaultOrder = 0;
			var htmlCount = Array();
			for (var i = 0; i <= defaultOrder; i++) {
                htmlCount.push(i);
            }
           if(nodeid !== 'pcns' || !response.match('class="group" data-org=')){
                var analysisTable = $('#'+nodeid+' table.data').DataTable({
                    "paging": false,
                    "searching": false,
                    "ordering": true,
                    "order": [[ defaultOrder, 'asc' ]],
                    "info": false,
                    columnDefs: [
                        { type: 'html', targets: htmlCount },
                        { type: 'suppressed', targets: '_all' }
                    ]
                });
                if (liteMessage != '') {
                    analysisTable.on('click', function () {
                        alert( liteMessage );
                    });
                }
            }
            setFloatingHeaderUnlessSubtable(nodeid);
            
            if(type=='providers-acute'){
                loadAnalysisDataTable(md5,'providers-mental-health','providers-mental-health', liteMessage,monthly);
                loadAnalysisDataTable(md5,'providers-community','providers-community', liteMessage,monthly);
                loadAnalysisDataTable(md5,'providers-ambulance','providers-ambulance', liteMessage,monthly);
                loadAnalysisDataTable(md5,'providers-independent','providers-independent', liteMessage,monthly);
                loadAnalysisDataTable(md5,'providers-non-nhs','providers-non-nhs', liteMessage,monthly);
            }
            if(type=='provider-sites-acute'){
                loadAnalysisDataTable(md5,'provider-sites-mental-health','provider-sites-mental-health', liteMessage,monthly);
                loadAnalysisDataTable(md5,'provider-sites-community','provider-sites-community', liteMessage,monthly);
                loadAnalysisDataTable(md5,'provider-sites-ambulance','provider-sites-ambulance', liteMessage,monthly);
                loadAnalysisDataTable(md5,'provider-sites-independent','provider-sites-independent', liteMessage,monthly);
                loadAnalysisDataTable(md5,'provider-sites-non-nhs','provider-sites-non-nhs', liteMessage,monthly);
            }
		}).fail(function(response){
            QueryRunCounter--;
            if (response.readyState < 4) {
                return true;
            } 
            ajaxDisplayError($('#'+nodeid));
            handleAjaxError(response);
		});
	}
    //console.log('loadAnalysisDataTable end',QueryRunCounter);       
}
var globalQueries = [];
var QueryRunCounter = 0;
var globalTriggerTimer = null;

globalQueries.push = function() { 
    Array.prototype.push.apply(this, arguments);
    //console.log('added', arguments);
    if(globalTriggerTimer == null){
        globalTrigger();
    }
};
globalQueries.unshift = function() { 
    Array.prototype.unshift.apply(this, arguments);
    //console.log('removed', arguments);
    if(globalTriggerTimer == null){
        globalTrigger();
    }
};

function globalTrigger(){
    //console.log('called globalTrigger', globalQueries);
    window.clearTimeout(globalTriggerTimer);
    globalTriggerTimer = null;
    //for(var i in globalQueries){    
    var tmpQueries = jQuery.extend(true, [], globalQueries);
    for(var i = 0; i < tmpQueries.length; i++){  
        var value = tmpQueries[i].toString();
        //console.log(QueryRunCounter);
        if(QueryRunCounter<2){ 
            //console.log(value, ' = value');
            //console.log('globalTrigger trigger',QueryRunCounter, globalQueries[i]);
            eval(value);
            globalQueries.splice(globalQueries.indexOf(value),1);
            //console.log(value, 'removed', globalQueries, QueryRunCounter);
        }else{
            if(!globalTriggerTimer){
                //console.log('globalTrigger setTimeout');
                globalTriggerTimer = setTimeout(globalTrigger,500);
            }
            break;
        }
    }
}

// Sections which contain multiple tables accessed via submenu dropdown
var analysisMultipleTables = ['providers', 'provider-sites'];

// The name of the first table in each section which contains multiple tables
var analysisMultipleFirst = 'acute';

/*
 * Implement floating headers unless the table is from providers OR provider sites and
 * it isn't the first table within that section (otherwise the headers disappear as the
 * table doesn't have a dedicated content section for the height calculations)
 */
function setFloatingHeaderUnlessSubtable(nodeid) {
    if (isSubgroup(nodeid) === false || isSubfirst(nodeid) === true) {
        setFloatThead(nodeid);
    }
}
 
/**
 * Determine if the table is part of a subgroup (e.g. providers / provider sites)
 */
function isSubgroup(nodeid) {
    var subgroup = false;
    $.each(analysisMultipleTables, function (key, value) {
        if (nodeid.indexOf(value) !== -1) {
            subgroup = true;
        }
    });

    return subgroup;
}

/**
 * Determine if the table is the first item in a subgroup
 */
function isSubfirst(nodeid) {
    var subfirst = false;
    if (nodeid.indexOf(analysisMultipleFirst) !== -1) {
        subfirst = true;
    }

    return subfirst;
}

/**
 * Implement floating headers for table in given section
 */
function setFloatThead(section) {
    var $table = $('#' + section + ' table');
    $table.floatThead({top: 147, zIndex: 10});
}

    
function ajaxDisplayError(node){
    var html = '<div class="alert alert-danger alert-dismissible" role="alert">';
    html += '<strong>Something went wrong while processing your query.</strong></br>Please refresh the page to try again or contact Harvey Walsh Support on: 01928 515 215 or Email <a href="mailto:support@harveywalsh.co.uk">support@harveywalsh.co.uk</a> if this issue continues to happen.';
    html += '</div>';
    node.append(html);
    return true;
}
$(function () {
    
    var hash = $('#hash').html();
    
    var defaultOrder = $('#analysis-tab-content table.data td.organisation').index();

    if (defaultOrder < 0) {
        defaultOrder = 0;
    }
    var htmlCount = Array();
    for (var i = 0; i <= defaultOrder; i++) {
        htmlCount.push(i);
    }
    
    $('#analysis-tab-content table.data').DataTable({
        "paging": false,
        "searching": false,
        "ordering": true,
        "order": [[defaultOrder, 'asc']],
        "info": false,
        columnDefs: [
            {type: 'none', targets: 0, searchable: false, orderable: false},
            {type: 'html', targets: htmlCount},
            {type: 'suppressed', targets: '_all'}
        ]
    });

    $('#analysis-tab-content').on('click touchend','table.has-parent td.group',function(){
        $('#analysis-tab-content table.has-parent:visible .primary:visible, #analysis-tab-content table.has-parent:visible .secondary:visible, #analysis-tab-content table.has-parent:visible .child-row:visible').hide();
        $('#analysis-tab-content table.has-parent:visible tr.'+$(this).data('org')).show();
    });
    
    $('#monthlyGroup label').on('change',function (e) {
        window.location.href = '/data/analysis/' + hash + '/0/' + getMonthlyFlag();
    });

    function getMonthlyFlag(){
        var monthly = 0;
        var monthlyLabel = $('#monthlyGroup label.active input').attr('id');

        switch(monthlyLabel){
            case 'monthly':
                monthly = 1;
            break;
            case 'yearly':
                monthly = 2;
            break;
            case 'quarterly':
                monthly = 3;
            break;
            //case 'cumulative':
            default:
                monthly = 0;
            break;
        }
        return monthly;
    }
    
    function getMonth(link) {
        var monthlyFlag = getMonthlyFlag();
        if (monthlyFlag === 0) {
            return 0;
        } else {
            if(typeof $(link).parents('td').last().attr('data-month')!='undefined'){
                return $(link).parents('td').last().attr('data-month');
            }
            return 0;
        }
    }

    $(document).on('click', '.drilldown_referrals', function (e) {
        e.preventDefault();
        var orgCode = $(this).parents('tr').attr('data-type');
        var modal = genModal('Referrals Breakdown');
        $.ajax({
            url: '/Data/getReferralBreakdown/' + orgCode + '/' + hash + '/0/' + getMonth(this),
            success: function (data) {
                modal.find('.modal-body').html(data);
                if ($('body #referrals-tab').children().length == 0) {
                    $('body .modal-body').find('a').addClass('disabled');
                    $('#referrals-tab-content').html('<p>There is no referral data.</p>');
                }
            },
            error: function (response) {
                if (response.readyState < 4) {
                    return true;
                }
                ajaxDisplayError(modal.find('.modal-body'));
            }
        });
    });
    
    $(document).on('click', '.drilldown_tot_spells', function (e) {
        e.preventDefault();
        var orgCode = $(this).parents('tr').attr('data-type');
        var modal = genModal('Spells Breakdown');
        
        var url = '/Data/getSpellsBreakdown/' + orgCode + '/' + hash + '/0/0/' + getMonth(this);
        
        if ($("#yearly").is(':checked')) {
            url += '/' + $("#mrp-lowerDate").val() + '/' + $("#mrp-upperDate").val();
        }        
        
        $.ajax({
            url: url,
            success: function (data) {              
                modal.find('.modal-body').html(data);
            },
            error: function (response) {
                if (response.readyState < 4) {
                    return true;
                }
                ajaxDisplayError(modal.find('.modal-body'));
            }
        });
    });

    $(document).on('click', '.drilldown_tot_ip', function (e) {
        QueryRunCounter++;
        e.preventDefault();
        var orgCode = $(this).parents('tr').attr('data-type');
        var modal = genModal('Admission Method Breakdown');
        
        var url = '/Data/getAdmissionMethodBreakdown/' + orgCode + '/' + hash + '/0/' + getMonth(this);
        
        if ($("#yearly").is(':checked')) {
            url += '/' + $("#mrp-lowerDate").val() + '/' + $("#mrp-upperDate").val();
        }
        
        $.ajax({
            url: url,
            success: function (data) {
                modal.find('.modal-body').html(data);
            },
            error: function (response) {
                if (response.readyState < 4) {
                    return true;
                }
                ajaxDisplayError(modal.find('.modal-body'));
            }
        });
    });

    $(document).on('click', '.drilldown_total_items, .drilldown_total_qty, .drilldown_total_actual_cost,.drilldown_total_nic_costs', function (e) {
        e.preventDefault();
        var orgCode = $(this).parents('tr').attr('data-type');
        var modal = genModal('Prescribing Breakdown');
        $.ajax({
            url: '/Data/getPrescribingBreakdown/' + orgCode + '/' + hash + '/' + getMonth(this),
            success: function (data) {
                modal.find('.modal-body').html(data);
            },
            error: function (response) {
                if (response.readyState < 4) {
                    return true;
                }
                ajaxDisplayError(modal.find('.modal-body'));
            }
        });
    });
    
    $(document).on('click', '#nav-sub-menu .nav-link', function () {
        ensureWindowAtTop();
    });

    $(document).on('click', '#providers-submenu .dropdown-menu a, #provider-sites-submenu .dropdown-menu a', function () {
        ensureWindowAtTop();
        var nodeid = $(this).attr('href').replace('#', '');
        if (isSubfirst('#' + nodeid) === false && tableHasData(nodeid)) {
            setFloatThead(nodeid);
        }
    });
    
    /**
     * Determine if a table has any results by checking number of cells in the body, this will always be 1
     * if it's still loading or if no results were found
     */
    function tableHasData(nodeid) {
        var cells = $('#' + nodeid + ' table >tbody >tr >td').length;

        if (cells > 1) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * If it isn't already, go back to the top of the window so you aren't left part-way down a table when 
     * you change the table you're looking at
     */
    function ensureWindowAtTop() {
        if ($(document).scrollTop() > 0) {
            window.scrollTo(0, 0);
        }
    }
    
    $(document).on('click', '.organisation .fas', function () {
        QueryRunCounter++;
        var activeCell = this;
        var orgCode = $(activeCell).parents('tr').attr('data-type');
        removeLoadingRow(orgCode);

        if ($("[data-parent-row='" + orgCode + "']").length > 0) {
            $("[data-parent-row='" + orgCode + "']").remove();
        } else {
            createLoadingRow(activeCell, orgCode);
            $.ajax({
                url: '/Data/getAnalysisCodeBreakdown/' + orgCode + '/' + hash + '/' + getMonthlyFlag(),
                success: function (response) {         
                    QueryRunCounter--;
                    removeLoadingRow(orgCode);
                    $(activeCell).closest('tr').after(response);
                },
                error: function () {         
                    QueryRunCounter--;
                    removeLoadingRow(orgCode);
                    alert("Analysis code breakdown error");
                }
            });
        }
    });
    
    function removeLoadingRow(orgCode) {
        $('#loading-row-' + orgCode).remove();
    }
    
    function createLoadingRow(activeCell, orgCode) {
        var tdCount = $(activeCell).closest('tr').children('td').length;
        var loadingCell = "<td colspan='" + tdCount + "'><i class='fas fa-circle-notch fa-spin'></i> Loading..</td>";
        var loadingRow = "<tr id='loading-row-" + orgCode + "'>" + loadingCell + "</tr>";
        $(activeCell).closest('tr').after(loadingRow);
    }

    //analysis by code breakdown
    $(document).on('click touchend', '.breakdown-codes.closed', function(event){
        event.preventDefault();
        event.stopPropagation();
        if(!$(this).hasClass('disabled')){
            var node = $(this);
            var table = node.closest('table');
            var row = node.closest('tr');
            var orgCode = row.attr('data-type');
            var dataTableInstance = $('#'+$(table).attr('id')).DataTable();
            var dataTableRow = addLoadingRow(dataTableInstance, row);
            var childClass = 'child-row';
            if(row.prevAll('.parent-row').length > 0){
                childClass += ' ' + row.prevAll('.parent-row').first().find('td').data('org');
            }
            disableBreakdownByCode();
            $.ajax({
                url: '/data/breakdown_by_code/' + orgCode + '/' + $('#hash').html(),
                dataType: 'JSON',
                success: function(data){
                    //loadingRow.remove().draw( false );
                    enableBreakdownByCode();
                    var html = '';
                    for(var r in data){
                        html += '<tr class="' + childClass + '">';
                        for(var c in data[r]){
                            html += '<td>' + formatValue(c, data[r][c]) + '</td>';
                        }
                        html += '</tr>';
                    }
                    addDataRow(dataTableRow, html);
                    node.replaceWith('<i class="far fa-caret-square-down breakdown-codes" data-org="' + node.data('org') + '"></i>');
                    //}
                },
                error: function(){
                    loadingRow.remove().draw( false );
                    enableBreakdownByCode();
                }
            })
        }
    });

    function addLoadingRow(dataTableInstance, row)
    {
        var loadingContent = 'Loading...<img src="/img/loading.gif" class="img-fluid"/>';
        var loadingClass = 'loading-row'
        if(dataTableInstance.length > 0){
            var dataTableRow = dataTableInstance.row(row);
            var loadingRow = dataTableRow.child(loadingContent, loadingClass);
            loadingRow.show();
            return dataTableRow;
        }else{
            var colCount = row.children('td').length;
            row.after('<tr id="breakdown-loading"><td colspan="'+colCount+'" class="'+loadingClass+'">' + loadingContent + '</td></tr>');
            return row;
        }
    }

    function addDataRow(dataTableRow, html)
    {
        if(typeof dataTableRow.child === 'function'){
            dataTableRow.child($(html)).show();
        }else{
            dataTableRow.after(html);
            $('#breakdown-loading').remove();
        }
    }

    function disableBreakdownByCode()
    {
        $('.breakdown-codes').addClass('disabled');
    }
    function enableBreakdownByCode()
    {
        $('.breakdown-codes').removeClass('disabled');
    }
    
    function suppressionReplacement(value)
    {
        return String(value).replace('5.0001', '*');
    }
    
    function numberWithCommas(x){
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return  parts.join('.');
    }

    function formatValue(label, value){
        if(isNaN(value)){
            return value;
        }
        if(label.match("cost")){
            return '&pound;' + numberWithCommas(roundTo(value,0));
        }
        if(label.match("avg")){
            return numberWithCommas(roundTo(value,2));
        }
        return numberWithCommas(suppressionReplacement(roundTo(value,4)));
    }

    function roundTo(num, scale) {    
        return +(Math.round(num + "e+"+scale)  + "e-"+scale);
    }
});